<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-subtitle> {{ wtc_title }} Test </v-card-subtitle>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="list"
            dense
            item-key="welltype_id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.welltype }}</td>
                <td>
                  <v-text-field dense v-model="item.frequency"> </v-text-field>
                </td>
                <td>
                  <v-text-field dense v-model="item.graceperiode">
                  </v-text-field>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-btn @click.stop="saveform"> Save </v-btn>

          <v-btn class="ma-1" color="blue darken-1" @click="closeDialog">
            Close
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-form>
</template>
<script>
import TESTWELLUPSERT from "../graphql/Dictionnary/Mutations/TESTWELLUPSERT.gql";
export default {
  components: {},
  props: {
    dialog: Boolean,
    wtc_title: String,
    list: Array,
  },
  data() {
    return {
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      items: [],
      max25chars: (v) => v.length <= 25 || "Input too long!",
      headers: [
        {
          text: "Well Type",
          value: "welltype",
          selected: true,
          type: "text",
        },
        {
          text: "Frequency",
          value: "frequency",
          selected: true,
          type: "text",
        },
        {
          text: "Grace Period",
          value: "graceperiode",
          selected: true,
          type: "text",
        },
      ],
    };
  },
  watch: {},
  mounted() {},
  computed: {},
  methods: {
    async saveform() {
      let lst = [];
      let x = { testwelltype: lst };
      this.list.forEach((element) => {
        lst.push({
          id: element.id,
          welltype_id: element.welltype_id,
          testtype_id: element.testtype_id,
          frequency: parseInt(element.frequency),
          graceperiode: parseInt(element.graceperiode),
        });
      });

      let r2 = await this.$maj(TESTWELLUPSERT, x);
      if (!r2.ok) {
        this.snackbar_text = "Saving Error";
        this.snackbar_color = "error";
        this.snackbar = true;
      } else this.$emit("save");
    },
    closeDialog: function () {
      this.$emit("close-dialog");
    },
  },
};
</script>
